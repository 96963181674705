import React from 'react'
import { graphql } from 'gatsby'
import { Container, css, Box } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import GatsbyConfig from '../../../gatsby-config';
import ContentText from '@solid-ui-components/ContentText'
import ContentButtons from '@solid-ui-components/ContentButtons'

const styles = {
  demoVideo: {
    width: '800px',
    height: '448px',
    '@media screen and (max-width: 820px)': {
      width: '700px',
      height: '392px',
    },
    '@media screen and (max-width: 720px)': {
      width: '600px',
      height: '336px',
    },
    '@media screen and (max-width: 620px)': {
      width: '500px',
      height: '280px'
    },
    '@media screen and (max-width: 520px)': {
      width: '400px',
      height: '224px'
    },
    '@media screen and (max-width: 420px)': {
      width: '300px',
      height: '175px'
    },
  }
};


const ContactPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo
        title='Demo'
        description={``}
        siteUrl={GatsbyConfig.siteMetadata.siteUrl}
        thumbnail={{
          hero: {
            src: '/srm_acronym_logo.png'
          }
        }}
      />
      {/* Blocks */}
      <Header />
      <Divider space='5' />
      <Container>

        <Divider space='5' />

        <ContentText
            content={[{
              color: "omegaDarker",
              text: "Book a Demo",
              variant: "h2",
            }]}
            mb='1'
            sx={{ marginTop: [`30px`, `0`, `0`, `0`]}}
        />
        <ContentText
            content={[{
              color: "omegaDarker",
              text: "We're excited to show you how SRM can help your business collect more Google reviews. Book a time that's convenient for you.",
              variant: "medium",
            },{
              color: "omegaDarker",
              text: "Until then, you can get a sneak of some of the capabilities of SRM in the videos lower down the page.",
              variant: "medium",
            }]}
        />

        <Divider space='3' />

        <iframe
          src="https://srm.youcanbook.me/?noframe=true&skipHeaderFooter=true"
          style={{
            width:'100%',
            height:'1000px',
            border:'0px',
            backgroundColor:'transparent',
          }}
          frameborder="0"
          allowtransparency="true"
        />

        <Divider space='5' />
        
        <ContentText
            content={[{
              color: "omegaDarker",
              text: "SRM with QuickBooks Online",
              variant: "h2",
            }]}
            mb='1'
            sx={{ marginTop: [`30px`, `0`, `0`, `0`]}}
        />
        <ContentText
            content={[{
              color: "omegaDarker",
              text: "In this video we show how to connect Simple RM to your existing QuickBooks Online account and how to setup the Simple RM's automated review request feature.",
              variant: "medium",
            },{
              color: "omegaDarker",
              text: "If you would like to follow the video step-by-step, use the button below to get to the Simple RM app listing shown at the start of video.",
              variant: "medium",
            }]}
        />
        <ContentButtons content={content['qb-listing-button'].buttons} />
        <Divider />
        <iframe css={css(styles.demoVideo)} src="https://www.youtube.com/embed/bQnUqwGKE3I?controls=0" title="YouTube video player one" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <Divider space='5' />
        <ContentText
            content={[{
              color: "omegaDarker",
              text: "SRMs Optimized Review Requests",
              variant: "h2",
            }]}
            mb='1'
            sx={{ marginTop: [`30px`, `0`, `0`, `0`]}}
        />
        <ContentText
            content={[{
              color: "omegaDarker",
              text: "Not all review request are equal.",
              variant: "medium",
            },{
              color: "omegaDarker",
              text: "In this video we show the reviewers experience and highlight the optimizations that make it as frictionless as possible.",
              variant: "medium",
            }]}
        />
        <Divider />
        <iframe css={css(styles.demoVideo)} src="https://www.youtube.com/embed/iQbp7e-5Rbk?controls=0" title="YouTube video player two" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        
        
      </Container>
      <Divider space='5' />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query demoBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/demo"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default ContactPage
